import React, { useEffect, useState } from "react";
import "./App.css";
import PhoneAuth from "./pages/phone-auth/phone-auth";
import Chat from "./pages/chat/chat";
import { auth } from "./api/firebase-config";
import "react-phone-input-2/lib/style.css";

const App: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    listenToAuth();
    handleResize(); // Check the screen size on initial load
    window.addEventListener("resize", handleResize); // Add resize listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up on unmount
    };
  }, []);

  function listenToAuth() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
  }

  function handleResize() {
    setIsMobile(window.innerWidth <= 768); // Set mobile threshold as needed
  }

  if (isMobile) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "white",
        }}
      >
        {!userId ? <PhoneAuth /> : <Chat userId={userId} />}
      </div>
    );
  }

  // Desktop layout
  return (
    // <div
    //   style={{
    //     height: "100%",
    //     width: "100%",
    //     background: "#f8e1cd",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    // <div className="app-container">
    <div className="app-container">
      {!userId ? <PhoneAuth /> : <Chat userId={userId} />}
    </div>

    // </div>
  );
};

export default App;
