import React, { useState, useRef, useEffect } from 'react';
import './country-code-selector.scss';
import { countryCodes } from '../../../../api/model/country-codes';


const CountryCodeSelector = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); 

  const selectedCode = countryCodes.find((c) => c.code === value) || countryCodes[0];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (code: any) => {
    onChange(code.code);
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="country-code-selector" ref={dropdownRef}>
      <div className="selected-option" onClick={toggleDropdown}>
        {selectedCode.country} {selectedCode.code}
        <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
      </div>
      {isOpen && (
        <div className="options-list">
          {countryCodes.map((code) => (
            <div
              key={code.code}
              className="option"
              onClick={() => handleOptionClick(code)}
            >
              {code.country} {code.code}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountryCodeSelector;
