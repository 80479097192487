// src/components/Chat.tsx
import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import {
  chatOpenedEvent,
  listenToMessages,
  sendMessage,
} from "../../api/firestore";
import "./chat.scss";
import { signOut } from "../../api/firebase-config";
import Header from "../../components/header/header";
import ChatMessage from "./component/chat-message/chat-message";
import moment from "moment";
import ChatInput from "./component/chat-input/chat-input";
import Loader from "../../components/loader/loader";

var chatOpened = false;

const Chat: React.FC<{ userId: string }> = ({ userId }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!chatOpened) {
      chatOpenedEvent(userId);
      chatOpened = true;
    }
    listenToMessages(userId, setMessages);
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    };

    scrollToBottom();

    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.userId === userId) {
        setShowLoader(true);
      } else {
        setShowLoader(false);
      }
    }
  }, [messages, messagesEndRef]);

  function firebaseTimestampToDate(seconds: number, nanoseconds: number) {
    const milliseconds = seconds * 1000 + nanoseconds / 1e6;
    return new Date(milliseconds);
  }

  const handleSendMessage = (message: string) => {
    if (message.trim() !== "") {
      sendMessage(message, userId);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-container__header">
        <Header showLogOutButton={true} />
      </div>
      <div className="chat-container__body">
        <div className="chat-container__messages">
          {messages.length == 0 ? (
            <div className="chat-container__loader">
              <Loader />
            </div>
          ) : (
            <>
              <div className="messages">
                {messages.map((msg) => (
                  <ChatMessage
                    key={msg.docId}
                    message={msg.text}
                    isMe={msg.userId === userId}
                    timestamp={`${moment(
                      firebaseTimestampToDate(
                        msg.createdAt.seconds,
                        msg.createdAt.nanoseconds
                      )
                    ).format("ll")} • ${moment(
                      firebaseTimestampToDate(
                        msg.createdAt.seconds,
                        msg.createdAt.nanoseconds
                      )
                    ).format("LT")}`}
                  />
                ))}
                {showLoader && (
                  <div className="chat-container__message-loader fade-out fade-in">
                    <Loader />
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </>
          )}
        </div>
        <div className="chat-container__input ">
          <ChatInput onSendClick={(message) => handleSendMessage(message)} />
        </div>
      </div>
    </div>
  );
};

export default Chat;

//  <div className="messages">
//         {messages.map((msg) => (
//           <div
//             key={msg.id}
//             className={`message ${msg.userId === userId ? "sent" : "received"}`}
//           >
//             <ReactMarkdown>{msg.text}</ReactMarkdown>
//           </div>
//         ))}
//         <div ref={messagesEndRef} />
//       </div>
//       <div className="input-area">
//         <input
//           type="text"
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//           onKeyDown={handleKeyPress}
//           placeholder="Type your message..."
//         />
//         <button onClick={handleSendMessage}>Send</button>
//       </div>
