import React from "react";

const UhLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M12.0815 2.06574C9.83881 0.518493 5.9823 1.22789e-09 3.12476 2.03562C1.07489 3.49588 0.0214844 6.16388 0.0214844 8.90963C0.0214844 13.9301 4.04145 18 9.00033 18C13.9592 18 17.9792 13.9301 17.9792 8.90963C17.9792 4.64927 15.0843 1.07344 11.1774 0.0883203C11.1394 0.078745 11.1223 0.133273 11.1583 0.148765C14.268 1.48814 16.744 4.63587 16.5911 8.22247C16.4296 12.0111 13.1919 15.1387 9.54282 15.186C5.76087 15.235 1.94735 11.9179 2.5191 7.83551C2.84998 5.47294 4.89168 3.33477 7.19216 3.02644C10.2458 2.61714 13.7089 5.10383 13.4746 8.51268C13.3399 10.4705 12.2305 11.9288 10.295 12.2541C8.3841 12.5754 5.90308 11.2246 5.9823 8.9964C6.03838 7.41924 7.80176 6.30365 9.16664 7.05373C9.61717 7.30135 10.7351 8.66865 9.63506 8.86318C9.31738 8.91943 9.10842 8.70016 8.89751 8.47884L8.89745 8.47878L8.89739 8.47871C8.74988 8.32395 8.6014 8.16817 8.41415 8.10497C7.5643 7.81807 6.99494 8.98556 7.32916 9.67358C8.20784 11.4825 11.0044 11.472 11.953 9.77033C12.3802 9.00385 12.3307 8.07018 12.0788 7.25504C11.4062 5.07763 8.86047 3.62716 6.72205 4.40583C4.12665 5.35088 2.80184 8.60102 4.32888 11.0279C5.74899 13.2849 8.71843 14.5397 11.2353 13.6865C12.072 13.4028 12.7746 12.9196 13.3979 12.2852C14.0965 11.5741 14.677 10.8116 15.0478 9.86704C16.2036 6.92251 14.4806 3.72081 12.0815 2.06574Z"
        fill="#E5E5E5"
      />
      <path
        d="M12.0815 2.06574C9.83881 0.518493 5.9823 1.22789e-09 3.12476 2.03562C1.07489 3.49588 0.0214844 6.16388 0.0214844 8.90963C0.0214844 13.9301 4.04145 18 9.00033 18C13.9592 18 17.9792 13.9301 17.9792 8.90963C17.9792 4.64927 15.0843 1.07344 11.1774 0.0883203C11.1394 0.078745 11.1223 0.133273 11.1583 0.148765C14.268 1.48814 16.744 4.63587 16.5911 8.22247C16.4296 12.0111 13.1919 15.1387 9.54282 15.186C5.76087 15.235 1.94735 11.9179 2.5191 7.83551C2.84998 5.47294 4.89168 3.33477 7.19216 3.02644C10.2458 2.61714 13.7089 5.10383 13.4746 8.51268C13.3399 10.4705 12.2305 11.9288 10.295 12.2541C8.3841 12.5754 5.90308 11.2246 5.9823 8.9964C6.03838 7.41924 7.80176 6.30365 9.16664 7.05373C9.61717 7.30135 10.7351 8.66865 9.63506 8.86318C9.31738 8.91943 9.10842 8.70016 8.89751 8.47884L8.89745 8.47878L8.89739 8.47871C8.74988 8.32395 8.6014 8.16817 8.41415 8.10497C7.5643 7.81807 6.99494 8.98556 7.32916 9.67358C8.20784 11.4825 11.0044 11.472 11.953 9.77033C12.3802 9.00385 12.3307 8.07018 12.0788 7.25504C11.4062 5.07763 8.86047 3.62716 6.72205 4.40583C4.12665 5.35088 2.80184 8.60102 4.32888 11.0279C5.74899 13.2849 8.71843 14.5397 11.2353 13.6865C12.072 13.4028 12.7746 12.9196 13.3979 12.2852C14.0965 11.5741 14.677 10.8116 15.0478 9.86704C16.2036 6.92251 14.4806 3.72081 12.0815 2.06574Z"
        fill="white"
      />
    </svg>
  );
};

export default UhLogo;
