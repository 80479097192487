// src/components/PhoneAuth.tsx
import React from "react";
import Header from "../../components/header/header";
import LoginForm from "./components/login-form/login-form";
import "./phone-auth.scss";
import EmailLoginForm from "./components/login-form/email-login";
import PhoneLoginForm from "./components/login-form/phone-login";

const PhoneAuth: React.FC = () => {
  return (
    <div className="phone-auth__container">
      <div className="phone-auth__header">
        <Header showLogOutButton={false} />
      </div>
      <div className="phone-auth__body">
        <div className="phone-auth__body--login-form">
          <PhoneLoginForm />
        </div>
      </div>
    </div>
  );
};

export default PhoneAuth;
