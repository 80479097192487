import React from "react";
import "./chat-message.scss";
import ReactMarkdown from "react-markdown";
import UhLogo from "../../../../svg/uh-logo";

interface ChatMessageProps {
  message: string;
  isMe: boolean;
  timestamp: string;
}

const ChatMessage = (props: ChatMessageProps) => {
  return (
    <div
      className={
        props.isMe
          ? "single-message__container is-me fade-in"
          : "single-message__container is-not-me fade-in"
      }
    >
      <div
        className={
          props.isMe
            ? "single-message__text is-me"
            : "single-message__text is-not-me"
        }
      >
        {!props.isMe && (
          <div className="single-message__logo">
            <UhLogo />
          </div>
        )}
        <div className="single-message__current-message">
          <ReactMarkdown>{props.message}</ReactMarkdown>
        </div>
      </div>
      {props.isMe && (
        <div className="single-message__info">{props.timestamp}</div>
      )}
    </div>
  );
};

export default ChatMessage;
