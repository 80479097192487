import React from "react";
import "./chat-input.scss";
import SendIcon from "../../../../svg/send-icon";

interface ChatInputProps {
  onSendClick: (message: string) => void;
}

const ChatInput = (props: ChatInputProps) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      sendMessageToServer();
    }
  };

  const sendMessageToServer = () => {
    if (inputValue.trim() !== "") {
      props.onSendClick(inputValue);
      setInputValue("");
      // Clear the input value after sending
    }
  };

  return (
    <>
      <div className="chat-input__container">
        <textarea
          placeholder="Type your message..."
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={inputValue}
          rows={3}
          cols={50}
          id="myTextarea"
        ></textarea>

        <div
          className="chat-input__container-icon"
          onClick={sendMessageToServer}
        >
          <SendIcon height="18" width="16" fill="black" />
        </div>
      </div>
      <div className="chat-input__disclaimer">
        Noah is under training. Inaccuracies in responses are possible.
      </div>
    </>
  );
};

export default ChatInput;
