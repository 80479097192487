// src/firebaseFunctions.ts
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
} from "firebase/firestore";
import { auth, firebaseConfig, firestore } from "./firebase-config";
import { Message } from "./model/message";
import axios from "axios";
import { signInWithCustomToken } from "firebase/auth";

function messagesCollectionRef(userId: string) {
  return collection(firestore, "users", userId, "messages");
}

// Function to send message
export const sendMessage = async (message: string, userId: string) => {
  const messageRef = messagesCollectionRef(userId);
  await addDoc(messageRef, {
    text: message,
    createdAt: new Date(),
    userId: userId,
    userType: "user",
  });
};

// Function to listen to messages
export const listenToMessages = (userId: string, callback: Function) => {
  const messageQuery = query(
    messagesCollectionRef(userId),
    orderBy("createdAt")
  );

  onSnapshot(messageQuery, (snapshot) => {
    const messages = snapshot.docs.map(
      (doc) =>
        ({
          ...doc.data(), // Spread the message data
          docId: doc.id, // Add the document ID
        } as Message & { docId: string })
    ); // Ensure TypeScript understands the new structure

    callback(messages);
  });
};

export const logOutUser = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error signing out:", error);
  }
};


export const sendOtpHttps = async (to: string, channel: string): 
  Promise<{ success: boolean; message: string }> => {

  const url = firebaseConfig.sendOtpUrl;
  console.log(`Sending to: ${to} channel: ${channel} url: ${url}`)
  try {
    const headers = {
      'Content-Type': 'application/json'
    }
      const response = await axios.post(url,
        { to, channel },
        { headers });
      return { success: true, message: 'OTP sent successfully' };
  } catch (error) {
      console.error('Error sending OTP:', error);
      return { success: false, message: 'Failed to send OTP' };
  }
};

export const verifyOtpHttps = async (to: string, otp: string, channel: string): 
  Promise<{ success: boolean; token?: string; message: string }> => {
  const url = firebaseConfig.verifyOtpUrl;
  console.log(`Verifying to: ${to} otp: ${otp} channel: ${channel}. url: ${url}`)
  try {
    const headers = {
      'Content-Type': 'application/json'
    }
      const response = await axios.post(url, {
          to,
          otp,
          channel
      }, { headers });
      return response.data;
  } catch (error) {
      console.error('Error verifying OTP:', error);
      return { success: false, message: 'Failed to verify OTP' };
  }
};

export async function loginWithCustomToken(token: string) {
  try {
    await signInWithCustomToken(auth, token);
  } catch (error) {
    console.error("Error signing in:", error);
  }
}

export function chatOpenedEvent(userId: string) {
  const eventRef = collection(firestore, "users", userId, "events", "chat_opened", "v1");
  addDoc(eventRef, {
    eventName: "chat_opened",
    updatedAt: new Date(),
  });
}