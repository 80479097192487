import React, { useState } from "react";
import "./login-form.scss";
import Loader from "../../../../components/loader/loader";
import {
  loginWithCustomToken,
  sendOtpHttps,
  verifyOtpHttps,
} from "../../../../api/firestore";
import CountryCodeSelector from "../country-code/country-code-selector";

const PhoneLoginForm = () => {
  const [inputValue, setInputValue] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [showEdit, setShowEdit] = useState(false);
  const [showTelInput, setShowTelInput] = useState(true);
  const [showOtpButton, setShowOtpButton] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isInputAPhone, setIsInputAPhone] = useState(true);

  const isPhoneNumber = (value) => true;

  const sendOtp = async () => {
    setLoader(true);
    const to = isInputAPhone ? `${countryCode}${phone}` : phone;
    const channel = isInputAPhone ? "sms" : "email";
    await sendOtpHttps(to, channel);
    setLoader(false);
    setShowOtpInput(true);
  };

  const verifyOtp = async () => {
    setLoader(true);
    setShowSubmitButton(false);
    const to = isInputAPhone ? `${countryCode}${phone}` : phone;
    const channel = isInputAPhone ? "sms" : "email";
    const verificationResponse = await verifyOtpHttps(to, otp, channel);

    if (verificationResponse.success && verificationResponse.token) {
      await loginWithCustomToken(verificationResponse.token);
    } else {
      setOtp("");
      setShowSubmitButton(true);
      setLoader(false);
      setErrorMessage("Invalid OTP");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setInputValue(value);
    setIsInputAPhone(isPhoneNumber(value));
    setShowOtpButton(true);
    setPhone(value);
  };

  const onGetOtpClick = () => {
    setShowTelInput(false);
    setShowEdit(true);
    setShowOtpButton(false);
    sendOtp();
  };

  const handleOtpFieldChange = (e) => {
    setOtp(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
    if (e.target.value.trim().length > 0) {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  };

  const handleEditClick = () => {
    setShowTelInput(true);
    setShowEdit(false);
    setShowOtpInput(false);
    setShowOtpButton(true);
    setShowSubmitButton(false);
    setOtp("");
  };

  const handleResendOtp = () => {
    setShowSubmitButton(false);
    setOtp("");
    onGetOtpClick();
  };

  const handleCountryCodeChange = (code) => {
    setCountryCode(code);
  };

  return (
    <div className="login-form__container">
      <div className="login-form__header">Login</div>
      <div className="login-form__body">
        <div className="login-form__body-phone">
          <label htmlFor="phone">Your phone number</label>

          {showTelInput ? (
            <div className="login-form__body-otp">
              <div className="input-group">
                {isPhoneNumber(inputValue) && (
                  <CountryCodeSelector
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                  />
                )}
                <input
                  type="text"
                  placeholder="Enter Phone Number"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </div>
            </div>
          ) : (
            <div
              className={
                inputValue.length > 0
                  ? "login-form__body-phone-input hasValue"
                  : "login-form__body-phone-input"
              }
            >
              <div
                className="login-form__body-phone-input_number"
                onClick={() => setShowTelInput(true)}
              >
                {inputValue.length > 0 ? `${inputValue}` : "Enter phone number"}
              </div>
              {showEdit && (
                <div
                  className="login-form__body-phone-input_edit"
                  onClick={handleEditClick}
                >
                  edit
                </div>
              )}
            </div>
          )}
        </div>
        {showOtpInput && (
          <div className="login-form__body-otp">
            <label htmlFor="otp">OTP</label>
            <input
              type="text"
              placeholder="Enter OTP"
              id="otp"
              name="otp"
              value={otp}
              onChange={handleOtpFieldChange}
            />
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <div className="resend-otp" onClick={handleResendOtp}>
              Resend OTP
            </div>
          </div>
        )}
      </div>
      <div className="login-form__actions" id="get-otp">
        {showOtpButton && (
          <button className="btn-submit" onClick={onGetOtpClick}>
            Get OTP
          </button>
        )}
        {showSubmitButton && (
          <button className="btn-submit" onClick={verifyOtp}>
            Submit
          </button>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default PhoneLoginForm;
