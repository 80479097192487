import React from "react";
import "./header.scss";
import LogOutIcon from "../../svg/logOutIcon";
import { logOutUser } from "../../api/firestore";
import HeaderSvg from "../../svg/header-svg";

interface HeaderProps {
  showLogOutButton: boolean;
}
const Header = (props: HeaderProps) => {
  const handleLogOutEvent = () => {
    logOutUser();
  };
  return (
    <div className="header-container">
      <div className="header-logo">Noah</div>
      {props.showLogOutButton && (
        <div className="header-icon">
          <LogOutIcon
            height="1.25rem"
            width="1.25rem"
            fill="#fff"
            onClick={handleLogOutEvent}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
