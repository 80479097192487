// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseProdConfig from "./config-prod.json";
import * as firebaseDevConfig from "./config-dev.json";

export const firebaseConfig = process.env.REACT_APP_ENV === "production"
? firebaseProdConfig
: firebaseDevConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);


function signOut() {
  auth.signOut();
}

export { auth, firestore, signOut };